import React from "react"
import styled from "@emotion/styled"

const StyledLink = styled.a`
  font-weight: 900;
  text-decoration: none;
  color: #000;
  border-bottom: 0.5px black dotted;
`
const ExteriorLink = ({ linkTarget, linkCopy }) => {
  return (
    <StyledLink href={linkTarget} target="_blank">
      {linkCopy}
    </StyledLink>
  )
}

export default ExteriorLink
